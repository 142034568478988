import React from "react";
import { useRegions } from "../../../data/initialData/hooks/useRegions";
import { Region } from "../../../data/models";
import {
  AutocompleteSelect,
  AutocompleteSelectType,
} from "./AutocompleteSelect";

type RegionSelectPropsType = Omit<
  AutocompleteSelectType<Region>,
  "data" | "getLabel"
>;


export const RegionSelect: React.FunctionComponent<RegionSelectPropsType> = (
  props
) => {
  const [regions, renderRegionLabel] = useRegions();

  return (
		<AutocompleteSelect<Region>
			{...(props as AutocompleteSelectType<Region>)}
			data={regions}
			getLabel={renderRegionLabel}
		/>
	);

};
