import React from "react";
import {
  Grid,
  CardContent,
  CardHeader,
  Card,
  Divider,
} from "@material-ui/core";
import PushNotificationsReportTable from "./PushNotificationsReportTable";
import { usePageStyles } from "../../pageStyles";
import { PageComponent } from "../../../core/navigation/types";
import { PageTitle } from "../../components/common/PageTitle";
import { usePushNotificationsListData } from "../../../data/reports/notifications/hooks/usePushnotificationsListData";

const PushNotificationsReport: PageComponent = ({ userAccessRights }) => {
  const pageStyles = usePageStyles();

  const [data, load] = usePushNotificationsListData();

  React.useEffect(()=>{
    load();
  },[load])

  return (
    <>
      <Grid container className={pageStyles.root}>
        <Grid item xs={12} className={pageStyles.gridItem}>
          <Card>
            <CardHeader
              title={<PageTitle title="pushNotifications" />}
            />
            <Divider />
            <CardContent>
              <PushNotificationsReportTable userAccessRights={userAccessRights} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PushNotificationsReport;
