import { LocalizationLanguage } from "./types";

export const srTranslation = {
	language: LocalizationLanguage.SR as LocalizationLanguage,
	decimalSeparator: ",",
	momentLocale: "sr",
	mainTitle: "NSCOMEX",
	tableNextPage: "Sledeća strana",
	tablePreviousPage: "Predhodna strana",
	tableFirsPage: "Prva strana",
	tableLastPage: "Poslednja strana",
	tableDisplayedRows: "{from}-{to} od {count}",
	tabledDisplayedPerPage: "redova",
	orderActView: "Pogledaj nalog",
	orderActReply: "Odgovori na nalog",
	orderWaitingProducts: "Molimo Vas sačekajte...",
	orderSideBuy: "KUPI",
	orderSideSell: "PRODAJ",
	listingOrdersTitle: "Nalozi",
	userOrdersTitle: "Moji nalozi",
	archiveOrdersTitle: "Arhiva",
	search: "Pretraga",
	companyClmName: "Naziv",
	companyClmHeadquorters: "Sedište",
	companyClmMemberFrom: "Član od",
	companyClmProvision: "Provizija",
	reporClmtName: "Naziv",
	reporTitle: "Izveštaji",
	reporDownloadTtl: "Skini",
	username: "Korisničko ime",
	password: "Lozinka",
	login: "Prijava",
	logout: "Odjava",
	orderChooseProduct: "Izaberite proizvod",
	noDataText: "Nema podataka",
	orderClmOrdNo: "Broj",
	orderTimeReceived: "Datum naloga",
	orderOrdType: "Tip",
	orderRefOrd: "Ref nalog",
	orderClientIdNo: "MB klijenta",
	orderClientName: "Naziv klijenta",
	orderProduct: "Proizvod",
	orderYear: "Rod",
	orderTradingPlace: "Mesto isporuke",
	orderFranco: "Pravila transporta",
	orderQty: "Količina",
	orderAllOrNothing: "Sve ili ništa",
	orderPrice: "Cena",
	orderPriceEur: "Cena (EUR)",
	orderPriceWithoutVat: "Cena bez PDV-a",
	orderPriceWithVat: "Cena (PDV)",
	orderPayType: "Način plaćanja",
	orderPayDays: "Dani plaćanja",
	orderFreeStorage: "Besplatno skladištenje",
	orderFreeStorageByDelivery: "Do isporuke",
	orderFreeStorageByDate: "Do datuma:",
	orderCheckPoint: "Kontrola kvaliteta",
	orderCheckPays: "Plaća kontrolnu kuću",
	orderExpiryDate: "Datum isteka naloga",
	orderStatus: "Status",
	orderQuality: "Kvalitet",
	numberOfDays: "Broj dana",
	navListing: "Listing",
	navMyOrders: "Moji nalozi",
	navArchive: "Arhiva",
	navCompanies: "Kompanije",
	navMembers: "Članovi",
	navProducts: "Proizvodi",
	navBrokers: "Brokeri",
	navEmployees: "Zaposleni",
	navUsers: "Korisnici",
	navRoles: "Uloge",
	navCodebook: "Šifarnik",
	navCountries: "Države",
	navTopRegion: "Region",
	navRegion: "Okrug",
	navSubRegion: "Podregion",
	navCities: "Gradovi",
	navCurrencies: "Valute",
	navWarehouses: "Skladišta",
	navTrading: "Trgovanje",
	navMainTable: "Glavna tabla",
	navOrders: "Nalozi",
	navTransactions: "Zaključnice",
	navMarketDepth: "Dubina tržišta",
	navCliring: "Kliring",
	navDeposits: "Depoziti",
	navMarginCalculations: "Obračun marže",
	navSurveillance: "Nadzor",
	navAlarmSetup: "Postavljanje alarma",
	navProductReport: "Izveštaji o proizvodima",
	navMemberReport: "Izveštaji o članovima",
	navReports: "Izvestaji",
	navDailyReports: "Dnevni izvestaji",
	navWeeklyReports: "Nedeljni izvestaji",
	navMonthlyReports: "Mesečni izvestaji",
	navQuaterlyReports: "Kvartalni izvestaji",
	navAnnualReports: "Godišnji izvestaji",
	navSECReports: "SEC izvestaji",
	navMembersReports: "Izvestaji o članovima",
	navOrdersReports: "Nalozi i zaključnice",
	navAccounting: "Knjiženje",
	navTaxes: "Porez i provizija",
	navInvoice: "Kreiranje faktura",
	navAccountingExport: "Eksport za knjiženje",
	navMembersDeposit: "Depoziti članova",
	empFirstName: "Ime",
	empLastName: "Prezime",
	empIdNumber: "JMBG",
	empOfficePhone: "Broj poslovnog telefon",
	empEmail: "Email",
	empStatus: "Status",
	empMidName: "Srednje ime",
	empGender: "Rod",
	empAddress: "Adresa",
	empZipCode: "Poštanski broj",
	empCity: "Grad",
	empPhone: "Broj telefona",
	empDateOfBirth: "Datum rođenja",
	empPosition: "Pozicija",
	empBranch: "Grana",
	empFax: "Faks",
	empShowTooltip: "Prikaži brokera",
	genderMale: "Muški",
	genderFemale: "Ženski",
	statusActive: "Aktivan",
	statusInactive: "Neaktivan",
	empEdit: "Ažuriraj",
	empDelete: "Obriši",
	empEditTitle: "Izmena",
	empCreateTitle: "Kreiranje brokera",
	cliName: "Skraćen naziv",
	cliIdNo: "Matični broj",
	cliTaxCode: "PIB broj",
	clijbkjs: "JBKJS",
	cliType: "Tip",
	cliForeign: "Strani",
	cliCity: "Grad",
	cliPhone: "Telefon",
	cliBroker: "Broker",
	cliActive: "Članarina",
	cliStatus: "Status",
	clilFullName: "Naziv",
	cliAddress: "Adresa",
	cliMailCity: "Grad za poštu",
	cliMailAddress: "Adresa za poštu",
	cliMobile: "Mobilni telefon",
	cliFax: "Faks",
	cliEmail: "Email",
	cliHasContract: "Ima ugovor",
	cliActiveFrom: "Aktivan od",
	cliActiveTo: "Aktivan do",
	cliCommission: "Provizija",
	cliCurrentTurnover: "Promet",
	cliComment: "Komentar",
	cilEditTitle: "Ažuriraj člana",
	cilCreateTitle: "Kreiraj člana",
	cilTitle: "Članovi",
	tableConfirmationDelete: "Da li ste sigurni?",
	tableDelete: "Obriši",
	tableAdd: "Dodaj",
	tableEdit: "Ažuriraj",
	tableCancel: "Otkaži",
	tableSave: "Saćuvaj",
	roleCode: "Šifra",
	roleName: "Naziv SR",
	roleNameEn: "Naziv EN",
	tableShow: "Prikaži",
	roleEdit: "Ažuriraj ulogu",
	roleCreate: "Kreiraj ulogu",
	roleNameColumn: "Naziv",
	roleIsEmployee: "Zaposlen",
	userUserName: "Ime",
	userLoginUser: "Korisničko ime",
	userLoginPass: "Lozinka",
	userChangePass: "Promena lozinke",
	userRoleId: "Uloga",
	userIsEmployee: "Zaposlen",
	userEmpId: "Zaposleni",
	userClientId: "Klijent",
	userCliPrsId: "Osoba klijenta",
	userSubscribedUntil: "Pristup do",
	userSubscrUnlimited: "Neograničen pristup",
	userIsActive: "Aktivan",
	userEditTitle: "Ažuriraj korisnika",
	userCreateTitle: "Kreiraj korisnika",
	tableBasciInfo: "Osnovni podaci",
	clientPersonTitle: "Lica",
	clientPersonName: "Ime",
	clientPersonIDNumber: "JMBG",
	clientPersonStatus: "Status",
	clientPersonEmpty: "Nema lica",
	clientBrokerTitle: "Brokeri",
	clientBrokerName: "Ime",
	clientBrokerIDNumber: "JMBG",
	clientBrokerStatus: "Status",
	clientBrokerEmpty: "Nema brokera",
	contractTitle: "Ugovori",
	contractNumber: "Broj",
	contractDate: "Datum",
	contractStatus: "Status",
	contractEmpty: "Nema ugovora",
	contractLincedContrNo: "Povezani ugovori",
	contractTypeDescription: "Vrsta",
	contractDescription: "Opis",
	contractPhoneOrder: "Broj telefona",
	contractWebUser: "Web korisnik",
	contractWebUserBlocked: "Web korisnik blokiran",
	contractTitleEdit: "Ažuriraj ugovor",
	contractTitleCreate: "Kreiraj ugovor",
	accountTitle: "Novčani računi",
	accountTitleEdit: "Ažuriraj novčani račun",
	accountTitleCreate: "Kreiraj novčani račun",
	accountNumber: "Broj računa",
	accountBank: "Banka",
	accountDefault: "Podrazumevani račun",
	accountClientBankCode: "Šifra banke klijenta",
	accountCurrency: "Valuta",
	accountForBuySell: "Kupovina/Prodaja",
	accountStatus: "Status",
	accountSideBuy: "Kupovna",
	accountSideSell: "Prodajna",
	accountSideBoth: "Obe",
	accountEmpty: "Nema novčanih računa",
	invalidDateMessage: "Datum nije validan",
	maxDateMessage: "Datum je isuviše veliki",
	minDateMessage: "Datum je isuviše mali",
	clientPersonEditTitle: "Ažuriraj osobu",
	clientPersonCreateTitle: "Kreiraj osobu",
	clientBrokerEditTitle: "Ažuriraj brokera",
	clientBrokerCreateTitle: "Kreiraj brokera",
	leavePageTitle: "Napuštate stranicu?",
	leavePageMessage: "Ako napustite ovu stranicu sve promene će biti izgubljene.",
	leavePageConfirm: "Napusti stranicu",
	leavePageCancel: "Ostani",
	calendarOK: "OK",
	calendarCancel: "Otkaži",
	calendarClear: "Obriši",
	productName: "Naziv",
	productNameEN: "Naziv (EN)",
	productGroupName: "Naziv grupe",
	productUnit: "Jedinica",
	productVat: "PDV",
	productHasYieldYear: "Godina roda",
	productStatus: "Status",
	productTitle: "Proizvodi",
	productEditTitle: "Ažuriraj proizvod",
	productCreateTitle: "Kreiraj proizvod",
	productAttributeTitle: "Produktni atributi",
	productAttributeEditTitle: "Ažuriraj produktni atribut",
	productAttributeCreateTitle: "Kreiraj produktni atribut",
	productAttributeName: "Naziv",
	productAttributeType: "Tip",
	productAttributeManditory: "Obavezan",
	productAttributeComparator: "Komparator",
	productAttributeStatus: "Status",
	productAttributeEmpty: "Nema atributa",
	productGroupNameSR: "Naziv (SR)",
	productGroupNameEN: "Naziv (EN)",
	productGroupStatus: "Status",
	productGroupTitle: "Produktne grupe",
	productGroupEditTitle: "Ažuriraj produktnu grupu",
	productGroupCreateTitle: "Kreiraj produktnu grupu",
	bankName: "Naziv Banke",
	bankCode: "Šifra",
	bankIdNumber: "Matični broj",
	navBanks: "Banke",
	bankEdit: "Ažuriraj banku",
	bankCreate: "Kreiraj banku",
	bankRegNumber: "Registarski broj",

	whsName: "Naziv magacina",
	whsCode: "Šifra",
	navWhs: "Magacini",
	whsEdit: "Ažuriraj magacin",
	whsCreate: "Kreiraj magacin",
	whsPort: "Najbliža luka",
	whsDistanceToPort: "Udaljenost do luke (km)",

	commission: "Provizija",
	requiredMessage: "Polje je obavezno",
	messageWeakPassword: "Lozinka nije dovoljno jaka",
	attributesTitle: "Atributi",
	attributesCreateTitle: "Kreiraj atribut",
	attributesEditTitle: "Ažuriraj atribut",
	attributeName: "Naziv",
	attributeNameEN: "Naziv (EN)",
	attributeOrder: "Redosled prikaza",
	attributeUnit: "Jedinica",
	attributeValuesTitle: "Vrednosti atributa",
	attributeValuesCreateTitle: "Kreiraj vrednost atributa",
	attributeValuesEditTitle: "Ažuriraj vrednost atributa",
	attributeValuesEmpty: "Nema vrednosti atributa",
	attributeValueName: "Naziv",
	attributeValueNameEN: "Naziv (EN)",
	attributeValueValue: "Vrednost",
	attributeValueValueEN: "Vrednost (EN)",
	attributeValueOrder: "Redosled prikaza",
	attributeValueStatus: "Status",
	currenciesTitle: "Valute",
	currenciesCreateTitle: "Kreiraj valutu",
	currenciesEditTitle: "Ažuriraj valutu",
	currenciesName: "Naziv",
	currenciesCode: "Oznaka",
	currenciesNumericCode: "Šifra",
	regName: "Region",
	regNameDistrict: "Okrug",
	parentRegName: "Region",
	countryReg: "Država",
	regEdit: "Ažuriraj region",
	regEditDistrict: "Ažuriraj okrug",
	regCreate: "Kreiraj region",
	regCreateDistrict: "Kreiraj okrug",
	curRateDate: "Datum kursa",
	curRateBuy: "Kupovni kurs",
	curRateSell: "Prodajni kurs",
	curRateMid: "Srednji kurs",
	curRateCurrency: "Valuta",
	curRateTitle: "Kursna lista",
	curRateEditTitle: "Ažuriraj kurs",
	curRateCreateTitle: "Kreiraj kurs",
	countryEdit: "Ažuriraj državu",
	countryCreate: "Kreiraj državu",
	countryCode: "Oznaka",
	countryName: "Naziv",
	countryNameEN: "Naziv (EN)",
	cityEdit: "Ažuriraj grad",
	cityCreate: "Kreiraj grad",
	cityName: "Naziv",
	cityZipCode: "Poštanski broj",
	cityRegion: "Okrug",
	citySubRegion: "Podregion",
	cityIsPort: "Luka",

	commissionTitle: "Provizije",
	commissionCreateTitle: "Kreiraj proviziju",
	commissionEditTitle: "Ažuriraj proviziju",
	commissionCode: "Šifra",
	commissionName: "Naziv",
	commissionNameEN: "Naziv (EN)",
	commissionType: "Tip",
	commissionStandard: "Standardna",
	commDetailsTitle: "Detalji provizije",
	commDetailsCreateTitle: "Kreiraj detalj provizije",
	commDetailsEditTitle: "Ažuriraj detalj provizije",
	commDetailsDate: "Datum od",
	commDetailsProductGroup: "Grupa proizvoda",
	commDetailsProduct: "Proizvod",
	commDetailsCurrency: "Valuta",
	commDetailsAmtCurrency: "Valuta iznosa",
	commDetailsAmtTypePercent: "Procenat",
	commDetailsAmtTypeAmount: "Iznos",
	commDetailsBuyAmtType: "Kupovni tip iznosa",
	commDetailsBuyPerc: "Kupovni procenat",
	commDetailsBuyMin: "Kupovna minimalna količina",
	commDetailsBuyMax: "Kupovna maksimalna količina",
	commDetailsSellAmtType: "Prodajni tip iznosa",
	commDetailsSellPerc: "Prodajni procenat",
	commDetailsSellMin: "Prodajni minimalna količina",
	commDetailsSellMax: "Prodajni maksimalna količina",
	productCode: "Šifra",
	clientPersonRole: "Uloga",
	clientPersonDateCertified: "Datum sertifikata",
	clientPersonPhone: "Telefon",
	clientPersonEmail: "Mail",
	orderRecieveMethod: "Način prijema",
	orderEditTitle: "Ažuriraj nalog",
	orderCreateTitle: "Kreiraj nalog",
	orderGoodsReadyFrom: "Roba spremna od",
	orderGoodsReadyTo: "Roba spremna do",
	orderAttrName: "Naziv",
	orderAttrValue: "Vrednost",
	orderAttrTitle: "Parametri kvaliteta",
	orderAttrCreateTitle: "Kreiraj atribut",
	orderAttrEditAttribute: "Ažuriraj atribut",
	orderReceiveMethod: "Nalog primljen",
	orderbrokName: "Broker",
	orderUserName: "Korisnik",
	orderCliPrsName: "Klijent-osoba",
	orderProdCode: "Šifra proizvoda",
	orderUnit: "Jedinica",
	orderQtyLeft: "Preostala količina",
	orderPriceVat: "Pdv",
	orderMinQty: "Minimalna količina",
	orderComment: "Napomena",
	orderCommission: "Provizija",
	ordergoodsRedyFrom: "Proizvod spreman od",
	ordergoodsRedyTo: "Proizvod spreman do",
	orderStatusDescr: "Status",
	orderCancelReplTime: "Vreme opoziva/modifikacije",
	orderPayData: "Datum plaćanja",
	orderSide: "Strana",
	orderCurrCode: "Valuta",
	orderexportReady: "Spremno za izvoz",
	orderBankGuarantee: "Garancija",
	orderActive: "Klijent nema aktivnu pretplatu",

	tradeTitle: "Zaključnice",
	tradeNo: "Broj",
	tradeTime: "Datum prometa",
	tranNoSell: "br. transakc. prodaje",
	orderNoSell: "br. naloga prodaje",
	clientNameSell: "Naziv prodavca",
	tranNoBuy: "br. transakc. kupovine",
	orderNoBuy: "br. naloga kupovine",
	clientNameBuy: "Naziv kupca",
	tradeProdCode: "Šifra proizvoda",
	tradeProdName: "Proizvod",
	tradeQty: "Količina",
	tradeStatusDescr: "Status",
	tradeDeliveryDescr: "Status isporuke",
	tradePrice: "Cena",
	listingOrdersBookTitle: "Knjiga naloga",
	orderCancel: "Povuci nalog",
	orderCancelQuestion: "Povuci nalog?",
	orderConfirmQuestion: "Potvrdi nalog?",
	orderCancelQuestionYes: "Da",
	orderCancelQuestionNo: "Ne",
	orderModify: "Modifikuj nalog",
	orderVerify: "Verifikuj nalog",
	orderConfirm: "Potvrdi nalog",
	orderReplay: "Kontra nalog",
	orderDelete: "Obriši nalog",
	tradeVatPerc: "Pdv %",
	tradePriceWithVat: "Cena sa pdv",
	tradeAmt: "Iznos",
	tradeAmtWithVat: "Iznos sa pdv",
	tradeTransCostSell: "Trosak transporta prodavca",
	tradeStorCostSell: "Trosak skladistenja prodavca",
	tradeTransCostBuy: "Trosak transporta kupca",
	tradeStorCostBuy: "Trosak skladistenja kupca",
	tradeCommPercSell: "Provizija prodavca %",
	tradeCommAmtSell: "Provizija prodavca ",
	tradeCommPercBuy: "Provizija kupca %", 
	tradeCommAmtBuy: "Provizija kupca", 
	tradeDeliveryWhsName: "Skladište",
	tradePayTypeDescr: "Način plaćanja",

	
	orderFilterConfirm: "Filtriraj",
	orderFilterCancel: "Otkaži",
	orderFilterFrom: "Nalog od",
	orderFilterTo: "Nalog do",
	tradeFilterFrom: "Zaključnica od",
	tradeFilterTo: "Zaključnica do",
	tradeViewAction: "Prikaži zaključnicu",
	tradeCancelAction: "Poništi zaključnicu",
	orderTradeQuestion: "Poništi zaključnicu?",
	filterFrom: "od",
	filterTo: "do",
	paymentTitle: "Plaćanja",
	paymentDataTime: "Datum",
	paymentClientCode: "Matbr klijenta",
	paymentClientName: "Naziv klijenta",
	paymentPayType: "Vrsta promene",
	paymentClmOrdNo: "Broj naloga",
	paymentTranNo: "Broj transakcije",
	paymentAmount: "Iznos",
	paymentAmountIn: "Ulaz",
	paymentAmountOut: "Izlaz",
	paymentCurrCode: "Valuta",
	paymentStatus: "Status",
	datefrom: "Od datuma",
	dateto: "Do datuma",
	paymentEdit: "Ažuriraj plaćanje",
	paymentCreate: "Kreiraj plaćanje",
	paymentSideIn: "Ulaz",
	paymentSideOut: "Izlaz",
	paymentSide: "Ulaz/Izlaz",
	paymentSideNonDedicated: "Bez namene",
	paymentSideDedicated: "Sa namenom",
	paymentSideDedication: "Namena",
	paymentBankGuarantee: "Bankarska garancija",
    paymentMatchingQty: "Odgovarajuća količina",

	ctrlGoodsTitle: "Kontrola",
	ctrlcontrolDate: "Datum",
	ctrltradeNo: "Broj zaključnice",
	ctrlsellerCode: "Matični br. prodavca",
	ctrlsellerName: "Prodavac",
	ctrlbuyerCode: "Matični br. kupca",
	ctrlbuyerName: "Kupac",
	ctrlproductCode: "Šifra proizvoda",
	ctrlproductName: "Proizvod",
	ctrlcheckPointDescr: "Mesto provere",
	ctrlcheckDoneByDescr: "Proveru izvršio",
	ctrlcheckPaysDescr: "Provera plaćanja",
	ctrlcontrolHouse: "Kontrolna kuća",
	ctrlcontrolReport: "Izveštaj",
	ctrlcontrolReportText: "Text Izveštaja",
	ctrlcontrolReportFile: "Fajl izveštaja",
	ctrlstatus: "Status",
	crtlGoodsEdit: "Ažuriraj kontrolu",
	ctrlGoodsCreate: "Kreiraj kontrolu",
	ctrlFilterFrom: "Od datuma",
	ctrlFilterTo: "Do datuma",

	dlvrDiliveryTitle: "Isporuke",
	dlvrDeliveryDate: "Datum",
	dlvrTradeNo: "Broj zaključnice",
	dlvrsellerCode: "Matični br. prodavca",
	dlvrsellerName: "Prodavac",
	dlvrbuyerCode: "Matični br. kupca",
	dlvrbuyerName: "Kupac",
	dlvrproductCode: "Šifra proizvoda",
	dlvrproductName: "Proizvod",
	dlvrDeliverQty: "Isporučena količina",
	dlvrLeftQty: "Preostala količina",
	dlvrTradeQty: "Ukupna količina",
	dlvrUnit: "Jedinica",
	dlvrCityCode: "PTT broj",
	dlvrCityName: "Grad",
	dlvrWarehouseCode: "Šifra skladišta",
	dlvrWarehouseName: "Skladište",
	dlvrstatus: "Status",
	dlvrEdit: "Ažuriraj isporuku",
	dlvrCreate: "Kreiraj isporuku",
	dlvrFilterFrom: "Od datuma",
	dlvrFilterTo: "Do datuma",
	dlvrValue: "Vrednost isporučene količine sa PDV",

	dlsDisputeTitle: "Sporna situacija",
	disDispSitDate: "Datum",
	disTradeNo: "Broj zaključnice",
	dissellerCode: "Matični br. prodavca",
	dissellerName: "Prodavac",
	disbuyerCode: "Matični br. kupca",
	disbuyerName: "Kupac",
	disproductCode: "Šifra proizvoda",
	disproductName: "Proizvod",
	disDisputeDescr: "Opis sporne situacije",
	disstatus: "Status",
	disEdit: "Ažuriraj situaciju",
	disCreate: "Kreiraj situaciju",
	disFilterFrom: "Od datuma",
	disFilterTo: "Do datuma",
	paymentClientOrders: "Nalozi klijenta",
	paymentClientTransactions: "Transakcije klijenta",

	guarGuaranteeTitle: "Garancije",
	guarEdit: "Ažuriraj garanciju",
	guarCreate: "Kreiraj garanciju",

	orderDeliveryLocation: "Mesto isporuke",
	filterReset: "Resetuj",
	orderMarket: "Market",
	orderMarketSpot: "Spot",
	orderMarketForward: "Forward",
	orderLocCountry: "Država",
	orderLocRegion: "Region",
	orderLocDistrict: "Oblast",
	orderLocCity: "Grad",
	orderLocWhs: "Skladište",
	tradeFulfillmentDate: "Kraj isporuke",
	startDeliveryDate: "Početak isporuke",
	navOrdersReport: "Knjiga naloga",

	stockFeeTitle: "Skladišne tarife",
	stockFeeEdit: "Ažuriraj skladišnu tarifu",
	stockFeeCreate: "Kreiraj skladišnu tarifu",
	stockFeeStartDate: "Datum početka",
	stockFeeStockFee: "Provizija",
	stockFeeStatus: "Status",

	transFeeTitle: "Transport cene",
	transFeeEdit: "Ažuriraj cenu transporta",
	transFeeCreate: "Kreiraj cenu transporta",
	transFeeStartDate: "Datum početka",
	transFeePice: "Cena",
	transFeeDistance: "Razdaljina",
	transFeeStatus: "Status",

	navTradesReport: "Knjiga zaključnica",
	navTradesReportAll: "Knjiga zaključnica - sve",

	dailyTradeReportTitle: "Dnevni izveštaj",
	dailyTradeReportProduct: "Proizvod",
	dailyTradeReportTurnoverRSD: "Promet RSD",
	dailyTradeReportTurnoverEUR: "Promet EUR",
	dailyTradeReportCountTransaction: "Broj transakcija",
	dailyTradeReportReportDate: "Datum izveštaja",
	dailyTradeReportReportWeightedRSD: "Prosečna cena RSD",
	dailyTradeReportReportWeightedEUR: "Prosečna cena EUR",
	dailyTradeReportQuantity: "Količina",

	monthlyTradeReportReportTitle: "KHOV Mesečni izveštaj",
	monthlyTradeReportReportMonth: "Mesec",
	monthlyTradeReportReportYear: "Godina",
	monthlyTradeReportReportDate: "Datum",
	monthlyTradeReportReportQty: "Količina",
	monthlyTradeReportReportWeightedRSD: "Prosečna cena RSD",
	monthlyTradeReportReportWeightedEUR: "Prosečna cena EUR",

	filterTooltip: "Filter",
	exportPDFTooltip: "Eksportuj PDF",
	exportXLSXTooltip: "Eksportuj Excel",
	exportXMLTooltip: "Eksportuj XML",
	printTooltip: "Štampaj",

	weeklyTradeReportTitle: "Nedeljni izveštaj",
	wTReportProductTitle: "Nedeljni izveštaj - Proizvodi",
	wTReportClientTitle: "Nedeljni izveštaj - Klijenti",
	wTReportDateTitle: "Nedeljni izveštaj - Datum",
	weeklyTradeWeek: "Nedelja",
	weeklyTradeReportProduct: "Proizvod",
	weeklyTradeReportClient: "Klijent",
	weeklyTradeReportDate: "Datum",
	weeklyTradeReportTurnoverRSD: "Promet RSD",
	weeklyTradeReportTurnoverEUR: "Promet EUR",
	weeklyTradeReportCountTransaction: "Broj transakcija",
	weeklyTradeReportQty: "Količina",
	wTReportWeightedPrice: "Prosečna cena RSD",
	wTReportWeightedPriceeur: "Prosečna cena EUR",
	wTableProduct: "Proizvod",
	wTableClient: "Klijent",
	wTableDate: "Datum",

	monthlyTradeReportTitle: "Mesečni izveštaj",
	mTReportProductTitle: "Mesečni izveštaj - Proizvodi",
	mTReportClientTitle: "Mesečni izveštaj - Klijenti",
	mTReportDateTitle: "Mesečni izveštaj - Datum",
	monthlyTradeMonth: "Mesec",
	monthlyTradeReportProduct: "Proizvod",
	monthlyTradeReportClient: "Klijent",
	monthlyTradeReportDate: "Datum",
	monthlyTradeReportTurnoverRSD: "Promet RSD",
	monthlyTradeReportTurnoverEUR: "Promet EUR",
	monthlyTradeReportCountTransaction: "Broj transakcija",
	monthlyTradeReportQty: "Količina",
	mTReportWeightedPrice: "Prosečna cena RSD",
	mTReportWeightedPriceeur: "Prosečna cena EUR",
	mTableProduct: "Proizvod",
	mTableClient: "Klijent",
	mTableDate: "Datum",

	annualTradeReportTitle: "Godišnji izveštaj",
	aTReportProductTitle: "Godišnji izveštaj - Proizvodi",
	aTReportClientTitle: "Godišnji izveštaj - Klijenti",
	aTReportDateTitle: "Godišnji izveštaj - Datum",
	annualTradeYear: "Godina",
	annualTradeReportProduct: "Proizvod",
	annualTradeReportClient: "Klijent",
	annualTradeReportDate: "Datum",
	annualTradeReportTurnoverRSD: "Promet RSD",
	annualTradeReportTurnoverEUR: "Promet EUR",
	annualTradeReportCountTransaction: "Broj transakcija",
	annualTradeReportQty: "Količina",
	aTReportWeightedPrice: "Prosečna cena RSD",
	aTReportWeightedPriceeur: "Prosečna cena EUR",
	aTableProduct: "Proizvod",
	aTableClient: "Klijent",
	aTableDate: "Datum",

	orderFundCheckButton: "U redu",
	orderFundCheckTitle: "Provere sredstava",

	weeklyNumofWeeks: "Broj nedelja",
	monthlyNumofmonths: "Broj meseci",

	TASTitle: "Transport i skladištenje",
	TASCalcStorage: "Obračunava se skladištenje",
	TASCalTransport: "Obračunava se transport",
	TASCaclulate: "Izračunaj",
	TASConfirm: "Potvrdi",
	TASDistance: "Dužina vozne rute",
	TASStorageCosts: "Troškovi skladištenja",
	TASTransportCosts: "Troškovi transporta",
	TASTooltip: "Obrada troškova transporta i skladištenja",
	TASCancel: "Otkaži",
	TASWarning: "Troškovi su beć obračunati! Ponovnim obračunom će prethodni biti poništen!",

	secCommDailyTradeReportTitle: "KHOV Dnevni izveštaj",
	secCommQuarterlyTradeReportTitle: "KHOV Kvartalni izveštaj",
	secCommAnnualyTradeReportTitle: "KHOV Godišnji izveštaj",
	secCommProduct: "Proizvod",
	secCommTurnoverRSD: "Promet RSD",
	secCommTurnoverEUR: "Promet EUR",
	secCommCountTransaction: "Broj transakcija",
	secCommReportDate: "Datum izveštaja",
	secCommReportWeightedRSD: "Prosečna cena RSD",
	secCommReportWeightedEUR: "Prosečna cena EUR",
	secCommReportQty: "Količina",
	secCommReportQuarter: "Kvartal",

	amtimnylndcommTradeNumber: "Broj transakcije",
	amtimnylndcommTradeDate: "Broj transakcije",
	amtimnylndcommProduct: "Proizvod",
	amtimnylndcommQuantity: "Količina",
	amtimnylndcommPrice: "Cena",
	amtimnylndcommAmount: "Vrednost",
	amtimnylndcommBuyer: "Kupac",
	amtimnylndcommSeller: "Prodavac",
	amtimnylndcommReportTitle: "Pranje novca",
	amtimnylndcommLimit: "Limit",

	exmemTitle: "Članovi berze",
	exmemMemberName: "Naziv Člana",
	exmemMemberId: "Matični broj",
	exmemTaxCode: "Pib",
	exmemAdress: "Adresa",
	exmemPhone: "Telefon",
	exmemManager: "Direktor",
	exmemTrader: "Trgovac",
	exmemContactPerson: "Kontakt osoba",

	memcontTitle: "Ugovori članova berze",
	memcontMember: "Član",
	memcontContractDate: "Datum ugovora",
	memcontExpireDate: "Datum isteka ugovora",
	memcontStatus: "Status",

	exchDateFrom: "Datum od",
	exchDateTo: "Datum do",
	exchTradeReview: "Pregled trgovanja",
	exchCommissionReview: "Pregled provizija",
	exchTradeByProduct: "Pregled trgovanja po proizvodu",
	exchBusinessActivities: "Poslovanje u periodu",
	exchTurnoverByClient: "Promet po klijentima",
	exchTurnoverByBrokers: "Promet po brokerima",
	exchWeightedPrice: "Ponderisane cene",
	exchDate: "Datum",
	exchTradeNumber: "Broj transakcije",
	exchTradeDate: "Datum transakcije",
	exchProduct: "Proizvod",
	exchPrice: "Cena",
	exchQuantity: "Količina",
	exchAmount: "Iznos",
	exchClient: "Klijent",
	exchTransactionDate: "Datum transakcije",
	exchTransactionNumber: "Broj transakcije",
	exchOrderNumber: "Broj naloga",
	exchSide: "Strana",
	exchCommission: "Provizija",
	exchTurnoverValue: "Promet",
	exchBuyersCommission: "Provizija kupca",
	exchSellersCommission: "Provizija prodavca",
	exchBuyerBroker: "Broker kupca",
	exchSellerBroker: "Broker prodavca",
	exchCommissionValue: "Iznos Provizije",
	exchTurnoverParticipation: "Učešće u prometu",
	exchCommissionParticipation: "Učešće u proviziji",
	exchBuyer: "Kupac",
	exchSeller: "Prodavac",
	exchTurnoverRsd: "Promet Rsd",
	exchTurnoverEur: "Promet EUR",
	exchWeightedPriceRsd: "Ponderisana cena Rsd",
	exchWeightedPriceEur: "Ponderisana cena Eur",

	annualNumofyears: "Broj godina",

	pleaseWait: "Molimo sačekajte...",

	createInvoiceTitle: "Automatsko kreiranje faktura",
	invoiceCreate: "Kreiraj fakturu",
	invoiceEdit: "Ažuriraj fakturu",
	invoiceTitle: "Fakture",
	invoiceInvNo: "Broj Fakture",
	invoiceInvDate: "Datum Fakture",
	invoiceInvTypeDescr: "Tip Fakture",
	invoiceTranDate: "Datum prometa",
	invoiceDueDate: "Datum valute",
	invoiceDescr: "Opis fakture",
	invoiceTranNo: "Broj transakcije",
	invoiceClientIdNo: "MB klijenta",
	invoiceClientName: "Klijent",
	invoiceTradeAmt: "Iznos trgovanja",
	invoiceTradeAmtWithVat: "Osnovica za obračun provizije",
	invoiceCommPerc: "% provizije",
	invoiceAmt: "Iznos provizije",
	invoiceVatPerc: "% pdv",
	invoiceVatAmt: "Iznos pdv",
	invoiceInvAmt: "Iznos za plaćanje",
	invoiceCurrCode: "Valuta",
	invoiceBookDate: "Datum knjiženja",
	invoiceCmpAccount: "Račun",
	invoiceModel: "Model",
	invoiceReffNo: "Poziv na broj",
	invoiceStatus: "Status",
	invoiceQuantity: "Količina",
	invoicePrice: "Cena",
	invoiceOrderNo: "Br.Naloga",
	invoiceProductName: "Proizvod",

	iInvoiceTooltip: "Kreiraj fakture",

	invoicingMessage: "Fakturisanje uspešno završeno.",
	invoicingMessageTitle: "Fakturisanje",
	invoicingMessageConfim: "OK",

	accountingOrderTooltip: "Kreiraj nalog",
	accountingOrderTitle: "Nalog za knjiženje",
	accountingOrderMessage: "Formiranje naloga završeno.",
	accountingOrderMessageTitle: "Formiranje naloga",
	accountingOrderConfim: "OK",

	tradingDataRefPriceTooltip: "Obracunaj referentne cene",
	tradingDataTitle: "Podaci o trgovanju",
	tradingDataDataTime: "Datum",
	tradingDataProductCode: "Oznaka proizvoda",
	tradingDataProductName: "Naziv proizvoda",
	tradingDataRefPrice: "Referentna cena",
	tradingDataBestBid: "Najbolja ponuda",
	tradingDataBestBidQty: "Najbolja ponuda kolicina",
	tradingDataBestAsk: "Najbolja traznja",
	tradingDataBestAskQty: "Najbolja tražnja količina",
	tradingDataOpenPrice: "Cena na otvaranju",
	tradingDataHighPrice: "Najviša cena",
	tradingDataLowPrice: "Najniža cena",
	tradingDataClosePrice: "Cena na zatvaranju",
	tradingDataVolume: "Količina",
	tradingDataAmount: "Iznos",
	tradingDataWeightedPrice: "Prosečna cena",
	tradingDataMidPrice: "Srednja cena",
	tradingDatadatefrom: "Od datuma",
	tradingDatadateto: "Do datuma",
	tradingDataEdit: "Ažuriraj referentnu cenu",

	marginsDataCalculationTooltip: "Obracunaj margine",
	marginsDataTitle: "Podaci o marginama",
	marginsDataCalcDate: "Datum",
	marginsDataTradeNo: "Broj zaključnice",
	marginsDataTradeDate: "Datum zaključnice",
	marginsDataSide: "Strana",
	marginsDataClientIdNo: "Matični broj",
	marginsDataClientName: "Klijent",
	marginsDataProductCode: "Oznaka proizvoda",
	marginsDataProductName: "Naziv proizvoda",
	marginsDataContrPrice: "Cena robe",
	marginsDataContrValue: "Iznos zaključnice",
	marginsDataGainLoss: "Dobitak/Gubitak",
	marginsDataMarginReq: "Zahtevana margina",
	marginsDataMarginCall: "Tražena doplata margine",
	marginsDataMarginBal: "Balans margine",
	marginsDataBalPlusGL: "Balans sa G/L",
	marginsDataCoverPerc: "Procenat pokrivenosti",
	marginsDataMaintTriggered: "Aktivacija održavanja margine",
	marginsDatadatefrom: "Od datuma",
	marginsDatadateto: "Do datuma",

	noFutureDate: "Datum ne sme biti veći od današnjeg.",

	orderQtyMatched: "Uparena količina",
	alarmTime: "Vreme alarma",
	alarmCrossName: "Cross alarm",
	alarmProductName: "Alarm za proizvod",
	alarmClientName: "Alarm za člana",
	notificationNewOrder: "Novi nalog",
	notificationNewTrade: "Nova transakcija",

	tradingDataMessage: "Obračun cena završen.",
	tradingDataMessageTitle: "Obračun cena",
	tradingDataConfim: "OK",
	tradingDataReportDate: "Datum obračuna cena",

	marginsDataMessage: "Obračun margina završen.",
	marginsDataMessageNoData: "Nema aktivnih terminskih ugovora za datum.",
	marginsDataMessageTitle: "Obračun margina",
	marginsDataConfim: "OK",
	marginsDataReportDate: "Datum obračuna margine",

	alarmAlmType: "Tip alarma",
	alarmUserName: "Korisnik",
	alarmClient: "Klijent",
	alarmProductCode: "Oznaka proizvoda",
	alarmProduct: "Proizvod",
	alarmActive: "Status",
	alarmEdit: "Ažuriraj alarm",
	alarmCreate: "Kreiraj alarm",
	alarmTitle: "Alarmi",
	alarmTypeCros: "Cross nalog",
	alarmTypeProduct: "Proizvod",
	alarmTypeUser: "Klijent",
	alarmUniqueMessage: "Alarm je vec postavljen",
	alarmTypeUserProduct: "Klijent i proizvod",

	alarmProductClientName: "Alram za klijent i proizvod",
	alarmBuyOrderNumber: "Broj kupovnog naloga",
	alarmSellOrderNumber: "Broj prodajnog naloga",
	allowSystemNotifications: "Ukljuci sistemske notifikacije",

	attrCompTypeLessThan: "<=",
	attrCompTypeEqual: "=",
	attrCompTypeMoreThan: ">=",
	attrCompType: "Komparator",
	productAttributeHasStandard: "Ima standardnu vrednost",
	productAttributeStandardValue: "Standardna vrednost",

	orderParameters: "Parametri naloga",
	tradeParameters: "Parametri transakcije",
	orderDepositeAmount: "Potreban iznos ",
	orderDepositeAmountPayed: "Plaćen iznos ",
	paymentAmtBal: "Ukupna sredstava",
	paymentAmtBalNonAlloc: "Nealocirana sredstava",

	attributeSRPS: "Prema PSTM", 
	// "SRPS kvalitet",

	paymentAmtOrdersBal: "Stanje na nalozima",
	paymentAmtTradesBal: "Stanje na transakicjama",

	deliveryTradeQty: "Ugovorena količina",
	deliveryLeftQty: "Preostalo za isporuku",

	clientCardTitle: "Kartica klijenta",

	grntTitle: "Bankarske garancije",
	grntNo: "Broj garancije",
	grntDate: "Datum",
	grntClientCode: "Matbr klijenta",
	grntClientName: "Naziv klijenta",
	grntBankCode: "Šifra banke",
	grntBankName: "Naziv banke",
	grntExpiryDate: "Datum isteka",
	grntAmount: "Iznos",
	grntCurrCode: "Valuta",
	grntStatus: "Status",
	grntEdit: "Ažuriraj garanciju",
	grntCreate: "Kreiraj garanciju",
	grntActView: "Prikaz",

	mainTableBid: "Kupovina",
	mainTableAsk: "Prodaja",

	tradesReportMessage: "Koju potvrdu preuzimate?",
	tradesReportTitle: "Preuzimanje",
	tradesReportMessageConfim: "Kupovina",

	errorTitle: "Greška",
	errorConfirm: "OK",
	errorProductDelete: "Proizvod koji je bio u nekom nalogu ne može da se briše.",
	errorProductAttributeDelete: "Atribut proizvoda koji je bio u nekom nalogu ne može da se briše.",
	errorClientDelete: "Brisanje se ne može izvršiti zbog konflikta reference podataka.",

	orderLogDataChangeTime: "Vreme promene",
	orderLogDataUserName: "Korisnik",
	orderLogDataActivity: "Akcija",
	orderLogDataStatus: "Status",
	orderLogDataRefOrder: "Veza nalog",
	orderLogDataTable: "Istorijat",
	orderLogDataEmpty: "Nema istorijata",

	newsTime: "Datum",
	newsTitle: "Naslov ",
	newsTitleen: "Naslov engleski",
	newsLokacija: "Naziv fajla",
	newsTitleTitle: "Vesti",
	newsEditTitle: "Ažuriraj vest",
	newsCreateTitle: "Kreiraj vest",
	newsShow: "Prikaži vest",

	payTypeDesc: "Način plaćanja (SR) ",
	payTypeDescEn: "Način plaćanja (EN)",
	payTypeTitle: "Način plaćanja",
	payTypeEditTitle: "ažuriraj način plaćanja",
	payTypeCreateTitle: "Kreiraj način plaćanja",
	payTypeStatus: "Status",
	payTypePonder: "Ponder",

	historyProd1: "Kukuruz",
	historyProd2: "Pšenica",
	historyProd3: "Soja nije GMO",

	AttrPsmtTitle: "Parametri kvaliteta prema PSTM",

	loginRemember: "Zapamti me",
	loginForget: "Zaboravi me",

	pushNotifications: "Notifikacije",
	pushNotification: "Notifikacija",

	notifPacking: "Pakovanje",
	notifOrigin: "Poreklo",
	notifDirectorate: "Direkcija",

};
