import { Country } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStateType, getFetchStateChange } from "../../models";

interface ICountryData {
  all?: Country[];
  searchTerm: string;
  displayData?: Country[];
  displayedCountry?: Country;
  fetchState: FetchStateType;
}

const initialState: ICountryData = {
  searchTerm: "",
  fetchState: { type: "not-started" },
};

const name = "countries";

const countrySlice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Country[] | undefined>) => ({
      ...state,
      all: action.payload,
      displayData: filterData(action.payload, state.searchTerm),
    }),
    setSearchTerm: (state, action: PayloadAction<string>) => ({
      ...state,
      searchTerm: action.payload.toLocaleLowerCase(),
      displayData: filterData(state.all, action.payload),
    }),
    setDisplayed: (state, action: PayloadAction<Country | undefined>) => ({
      ...state,
      displayedCountry: action.payload,
    }),
    setFetchState: (state, action: PayloadAction<FetchStateType>) => ({
      ...state,
      displayedCountry:
        action.payload.type === "successful"
          ? undefined
          : state.displayedCountry,
      all: action.payload.type === "successful" ? undefined : state.all,
      displayData:
        action.payload.type === "successful" ? undefined : state.displayData,
      fetchState: getFetchStateChange(state.fetchState, action.payload),
    }),
  },
});

const filterData = (data?: Country[], searchTerm?: string) =>
  searchTerm === undefined || searchTerm === ""
    ? data
    : data?.filter(
        (e) =>
          (e.cntId &&
            e.cntId
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
          (e.cntName &&
            e.cntName
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase())) ||
          (e.cntNameen &&
            e.cntName
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase().toLocaleLowerCase()))
      );

export const {
  setData,
  setSearchTerm,
  setDisplayed,
  setFetchState,
} = countrySlice.actions;

export default countrySlice.reducer;
