import React from "react";
import { useEditCityState } from "../../../data/listing/cities/hooks/useEditCityState";
import { useCityValidator } from "../../../data/listing/cities/hooks/useCityValidator";
import { useCityUpsert } from "../../../data/listing/cities/hooks/useCityUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import {
  Card,
  Grid,
  CardHeader,
  Divider,
  CardContent,
} from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { City } from "../../../data/listing/cities/types";
import { StringInput } from "../../components/common/StringInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { PageTitle } from "../../components/common/PageTitle";
import { citiesLocation } from "./Locations";
import { RegionSelect } from "../../components/common/RegionSelect";
import { Region } from "../../../data/models";
import { BoolInput } from "../../components/common/BoolInput";
import { Status } from "../../../data/models";

export interface EditCityInfo {
  cityId?: string;
}

export interface EditCityProps extends RouteComponentProps<EditCityInfo> {}

export const EditCityPage: React.FunctionComponent<EditCityProps> = (props) => {
  const { getString } = React.useContext(LocalizationContext);
  const pageStyles = usePageStyles();

  const [city, editCity, changed] = useEditCityState(props.match.params.cityId);
  const [validationState, validate] = useCityValidator();
  const [fetchState, upsert] = useCityUpsert();
  const history = useHistory();

  const unsavedChanges = fetchState.type === "not-started" && changed;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      editCity(event.target.name as any, event.target.value);
    },
    [editCity]
  );

  const changeRegion = React.useCallback(
    (region: Region | null) => {
      editCity("regionId", region ? region.regId : "");
    },
    [editCity]
  );

  const save = React.useCallback(() => {
    if (fetchState.type === "successful") {
      history.push(citiesLocation);
    } else if (fetchState.type !== "started") {
      const normalized = validate(city);
      if (normalized) {
        upsert(normalized);
      }
    }
  }, [city, validate, fetchState, history, upsert]);

  const pageTitle = city && city.cityId ? "cityEdit" : "cityCreate";

  if (!city) {
    return null;
  }

  return (
    <Grid container className={pageStyles.root}>
      <Grid item xs={12} className={pageStyles.gridItem}>
        <Card>
          <CardHeader
            title={
              <PageTitle title={pageTitle} backLocation={citiesLocation} />
            }
          />
          <Divider />
          <CardContent>
            <Grid
              container
              item
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={6}
            >
              <Grid item xs={2}>
                <StringInput<City>
                  property="cityName"
                  item={city}
                  validationState={validationState}
                  onChange={handleChange}
                  label={getString("cityName")}
                />
              </Grid>
              <Grid item xs={3}>
                <StringInput<City>
                  property="zipCode"
                  item={city}
                  validationState={validationState}
                  onChange={handleChange}
                  label={getString("cityZipCode")}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={6}
            >
              <Grid item xs={3}>
                <RegionSelect
                  label={getString("cityRegion")}
                  predicate={(region) => region.regId === city.regionId}
                  onChangeItem={changeRegion}
									required={validationState.regionId !== undefined}
									error={
										validationState.regionId !== undefined &&
										!validationState.regionId
									}                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={6}
            >

            <Grid item xs={1}>
									<BoolInput<City>
										property="isPort"
										item={city}
										onChange={handleChange}
										falsyValue={Status.Inactive}
										thrutyValue={Status.Active}
										label={getString("cityIsPort")}
									/>
								</Grid>
                </Grid>
            <FabSave fetchState={fetchState} onClick={save} />
            <LeavePrompt shouldShow={unsavedChanges} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
