import React from "react";
import { useEditAttributeState } from "../../../../data/listing/products/hooks/attributes/useEditAttributeState";
import { useAttributeValidator } from "../../../../data/listing/products/hooks/attributes/useAttributeValidator";
import { useAttributeUpsert } from "../../../../data/listing/products/hooks/attributes/useAttributeUpsert";
import { ProductAttribute, ProducAttributeManditory, ProducAttributeHasStandard } from "../../../../data/listing/products/types";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../../pageStyles";
import { LocalizationContext } from "../../../../core/localization/Localization";
import { Card, Grid, CardHeader, Divider, CardContent } from "@material-ui/core";
import { FabSave } from "../../../components/common/FabSave";
import { StringInput } from "../../../components/common/StringInput";
import { getProductEditLocation, ProductEditParts } from "../Locations";
import { ProductAttributeSelect } from "../../../components/common/ProductAttributeSelect";
import { BoolInput } from "../../../components/common/BoolInput";
import { Status } from "../../../../data/models";
import { LeavePrompt } from "../../../components/common/LeavePrompt";
import { PageTitle } from "../../../components/common/PageTitle";
import { NumberInput } from "../../../components/common/NumberInput";
import { AttributeType } from "../../../../data/listing/attributes/types";

export interface EditAttributeInfo {
	attributeId?: string;
	productId: string;
}

export interface EditAttributeProps extends RouteComponentProps<EditAttributeInfo> {}

export const EditAttribute: React.FunctionComponent<EditAttributeProps> = props => {
	const { getString } = React.useContext(LocalizationContext);
	const pageStyles = usePageStyles();

	const { productId, attributeId } = props.match.params;
	const [attribute, editAttribute, changed] = useEditAttributeState(productId, attributeId);
	const [validationState, validate] = useAttributeValidator();
	const [fetchState, upsert] = useAttributeUpsert();
	const history = useHistory();

	const unsavedChanges = fetchState.type === "not-started" && changed;

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editAttribute(event.target.name as any, event.target.value);
		},
		[editAttribute]
	);

	const handleChangeNum = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			editAttribute(event.target.name as any, parseInt(event.target.value));
		},
		[editAttribute]
	);

	const backLocation = React.useMemo(() => getProductEditLocation(ProductEditParts.Attributes, productId), [productId]);

	const save = React.useCallback(() => {
		if (fetchState.type === "successful") {
			history.push(backLocation);
		} else if (fetchState.type !== "started") {
			const normalized = validate(attribute);
			if (normalized) {
				upsert(normalized);
			}
		}
	}, [attribute, validate, fetchState, history, upsert, backLocation]);

	const pageTitle =
		attribute && attribute.prodAttrId ? "productAttributeEditTitle" : "productAttributeCreateTitle";

	if (!attribute) {
		return null;
	}

	return (
		<Grid container className={pageStyles.root}>
			<Grid item xs={12} className={pageStyles.gridItem}>
				<Card>
					<CardHeader title={<PageTitle title={pageTitle} backLocation={backLocation}/>} />
					<Divider />
					<CardContent>
						<Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
							<Grid item xs={3}>
								<ProductAttributeSelect
									label={getString("productAttributeTitle")}
									predicate={attr => attr.attrId === attribute.attributeId}
									required={validationState.attributeId !== undefined}
									error={
										validationState.attributeId !== undefined &&
										!validationState.attributeId
									}
									onChangeItem={attr => {
										editAttribute("attributeId", attr ? attr.attrId : undefined);
										editAttribute("prodAttrName", attr ? attr.attrName : undefined);
										editAttribute("type", attr ? attr.attrType : undefined);
										editAttribute("mandatory", attr ? attr.mandatory : undefined);
										editAttribute("comparator", attr ? attr.comparator : undefined);
										editAttribute("hasStandard", attr?.attrType.trimEnd() ==  AttributeType.Select.trimEnd() ? ProducAttributeHasStandard.False : ProducAttributeHasStandard.True);
									}}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="flex-start"
							spacing={4}
						>
							<Grid item xs={3}>
								<StringInput<ProductAttribute>
									property="prodAttrName"
									item={attribute}
									validationState={validationState}
									onChange={handleChange}
									label={getString("productAttributeName")}
									disabled
								/>
							</Grid>
							<Grid item xs={3}>
								<StringInput<ProductAttribute>
									property="type"
									item={attribute}
									validationState={validationState}
									onChange={handleChange}
									label={getString("productAttributeType")}
									disabled
								/>
							</Grid>
							<Grid item xs={3}>
								<StringInput<ProductAttribute>
									property="orderNo"
									item={attribute}
									validationState={validationState}
									onChange={handleChangeNum}
									label={getString("attributeOrder")}
									type="number"
								/>
							</Grid>


							<Grid
								container
								item
								direction="row"
								justify="flex-start"
								alignItems="center"
								spacing={3}
							>
								<Grid item xs={2}>
									<BoolInput<ProductAttribute>
									property="hasStandard"
									item={attribute}
									onChange={handleChange}
									falsyValue={ProducAttributeHasStandard.False}
									thrutyValue={ProducAttributeHasStandard.True}
									label={getString("productAttributeHasStandard")}
									disabled  ={attribute.type.trimEnd() ==  AttributeType.Select.trimEnd() }
									/>
								</Grid>
								<Grid item xs={3}>
									<NumberInput<ProductAttribute>
										property="standardValue"
										item={attribute}
										validationState={validationState}
										onChange={handleChange}
										label={getString("productAttributeStandardValue")}
										disabled  ={attribute.type.trimEnd() ==  AttributeType.Select.trimEnd() || attribute.hasStandard==ProducAttributeHasStandard.False}
										error={!(validationState.hasStandard === undefined || validationState.hasStandard)}
										money
									/>
								</Grid>
							</Grid>

							<Grid item xs={3}>
								<BoolInput<ProductAttribute>
									property="mandatory"
									item={attribute}
									onChange={handleChange}
									falsyValue={ProducAttributeManditory.False}
									thrutyValue={ProducAttributeManditory.True}
									label={getString("productAttributeManditory")}
									
								/>
							</Grid>
							<Grid item xs={3}>
								<BoolInput<ProductAttribute>
									property="status"
									item={attribute}
									onChange={handleChange}
									falsyValue={Status.Inactive}
									thrutyValue={Status.Active}
									label={getString("productAttributeStatus")}
								/>
							</Grid>
						</Grid>
						<FabSave fetchState={fetchState} onClick={save} />
						<LeavePrompt shouldShow={unsavedChanges} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
