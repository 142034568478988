import { Product, ProductValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const roleValidationState: ProductValidationState = {
	prodName: true,
	prodNameen: true,
	productGroupId: true,
	prodCode: true,
	unit: true,
	vat: true,
};

export const useProductValidator = createUseValidator<Product, ProductValidationState>(roleValidationState);
