import React from "react";
import { useEditCountryState } from "../../../data/listing/countries/hooks/useEditCountryState";
import { useCountryValidator } from "../../../data/listing/countries/hooks/useCountryValidator";
import { useCountryUpsert } from "../../../data/listing/countries/hooks/useCountryUpsert";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { usePageStyles } from "../../pageStyles";
import { LocalizationContext } from "../../../core/localization/Localization";
import {
  Card,
  Grid,
  CardHeader,
  Divider,
  CardContent,
} from "@material-ui/core";
import { FabSave } from "../../components/common/FabSave";
import { Country } from "../../../data/listing/countries/types";
import { StringInput } from "../../components/common/StringInput";
import { LeavePrompt } from "../../components/common/LeavePrompt";
import { PageTitle } from "../../components/common/PageTitle";
import { countriesLocation } from "./Locations";

export interface EditCountryInfo {
  cntId?: string;
}

export interface EditCountryProps
  extends RouteComponentProps<EditCountryInfo> {}

export const EditCountryPage: React.FunctionComponent<EditCountryProps> = (
  props
) => {
  const { getString } = React.useContext(LocalizationContext);
  const pageStyles = usePageStyles();

  const [country, editCountry, changed] = useEditCountryState(
    props.match.params.cntId
  );
  const [validationState, validate] = useCountryValidator();
  const [fetchState, upsert] = useCountryUpsert();
  const history = useHistory();

  const unsavedChanges = fetchState.type === "not-started" && changed;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      editCountry(event.target.name as any, event.target.value);
    },
    [editCountry]
  );

  const save = React.useCallback(() => {
    if (fetchState.type === "successful") {
      history.push(countriesLocation);
    } else if (fetchState.type !== "started") {
      const normalized = validate(country);
      if (normalized) {
        upsert(normalized);
      }
    }
  }, [country, validate, fetchState, history, upsert]);

  const pageTitle = country && country.cntId ? "countryEdit" : "countryCreate";

  if (!country) {
    return null;
  }

  return (
    <Grid container className={pageStyles.root}>
      <Grid item xs={12} className={pageStyles.gridItem}>
        <Card>
          <CardHeader
            title={
              <PageTitle title={pageTitle} backLocation={countriesLocation} />
            }
          />
          <Divider />
          <CardContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item xs={2}>
                <StringInput<Country>
                  property="cntId"
                  item={country}
                  validationState={validationState}
                  onChange={handleChange}
                  label={getString("countryCode")}
                />
              </Grid>       

               <Grid item xs={2}>
                <StringInput<Country>
                  property="cntName"
                  item={country}
                  validationState={validationState}
                  onChange={handleChange}
                  label={getString("countryName")}
                />
              </Grid>

              <Grid item xs={3}>
                <StringInput<Country>
                  property="cntNameen"
                  item={country}
                  validationState={validationState}
                  onChange={handleChange}
                  label={getString("countryNameEN")}
                />
              </Grid>
            </Grid>
            <FabSave fetchState={fetchState} onClick={save} />
            <LeavePrompt shouldShow={unsavedChanges} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
