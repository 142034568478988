import { Country, CountryValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";

export const countryValidationState: CountryValidationState = {
	cntId: true,
	cntName: true,
	cntNameen: true,
};

export const useCountryValidator = createUseValidator<Country, CountryValidationState>(countryValidationState);
