import { Warehouse, WhsValidationState } from "../types";
import { createUseValidator } from "../../../../core/util/hooks";
import { countDecimals } from "../../../../core/util/functions";

export const whsValidationState: WhsValidationState = {
  whsCode: true,
  whsName: true,
  cityId: true,
  address: true,  
//  distanceToPort: true,
};

/*export const useWhsValidator = createUseValidator< Warehouse, WhsValidationState>(
  whsValidationState,
  (item, validation) => ({
    ...validation,
    price: validation.distanceToPort && !!item.distanceToPort && countDecimals(item.distanceToPort) <= 2 ,
  }));  */


  export const useWhsValidator = createUseValidator< Warehouse, WhsValidationState>(
    whsValidationState,
    (item, validation) => ({
      ...validation,
    }));
  